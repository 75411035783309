<template>
    <div>
        <div class="model-main">
            <div class="model-info" v-loading="loading">
                <div class="model-info-l">
                    <div class="video-box">
                        <div class="video-t" v-if="modelSceneInfo.video_url">
                            <div class="video-t-l">名称：<span>{{ modelSceneInfo.scene_name }}</span></div>
                            <div class="video-t-l">比例：<span>{{ modelSceneInfo.ratio == '1' ? '横版' : '竖版' }}</span>
                            </div>
                            <div class="video-t-l">类别：<span class="block">{{
                                modelSceneInfo.model_type == '1' ? '绿幕' : modelSceneInfo.model_type == '2' ? '商务' : '休闲'
                            }}</span>
                            </div>
                            <div class="video-t-l">时长：<span>{{ modelSceneInfo.video_time | SecondToDate }}</span></div>
                            <div class="video-t-l" style="width: 66%;">简介：<span>{{ modelSceneInfo.describe }}</span></div>
                            <div class="close" @click="close"><i class="el-icon-close"></i></div>
                        </div>
                        <div class="video-c">
                            <video :key="modelSceneInfo.id" v-if="modelSceneInfo.video_url" autoplay controls="controls"
                                class="video_cover" :poster="modelSceneInfo.thumbnail" :src="modelSceneInfo.demo_video"
                                controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
                            <img v-else class="cover" :src="info.thumbnail" alt="">
                        </div>
                    </div>
                    <div class="video-info">
                        <div class="banner">
                            <el-image class="head" :src="info.thumbnail" fit="cover"></el-image>
                            <div class="text">快将模特分享给好友吧～</div>
                            <div class="copy"><img src="@/assets/images/digital/link.png" alt=""> 复制链接</div>
                        </div>
                        <div class="video-info-t1">
                            <div class="name">{{ info.name }} <img class="sex" v-if="info.gender == 1"
                                    src="@/assets/images/sex/nan.png" alt=""><img class="sex" v-if="info.gender == 2"
                                    src="@/assets/images/sex/nv.png" alt=""> </div>
                            <div class="hot"><img src="@/assets/images/digital/hot.png" alt=""> {{ info.hits }}</div>
                        </div>
                        <div class="video-info-t2">
                            <span>标签：</span>
                            <span class="label" v-for="item, index in info.labelList" :key="index">{{ item }}</span>
                        </div>
                        <div class="video-info-t2">
                            <span>年龄：</span>
                            <span>{{ info.age }}岁</span>
                            <span>星座：</span>
                            <span>{{ info.constellation }}</span>
                        </div>
                        <div class="video-info-t2">
                            {{ info.describe }}
                        </div>
                    </div>
                </div>
                <div class="model-info-r">
                    <div class="model-info-t">
                        <div class="model-info-header">
                            <div class="model-tit" style="padding-right: 40px;">模特分身</div>
                            <div class="tit">分身类型：</div>
                            <div class="select-box">
                                <el-select size="small" v-model="model_type" placeholder="全部" clearable
                                    @change="getScenceList">
                                    <el-option v-for="item in model_type_list" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="tit">比例：</div>
                            <div class="select-box">
                                <el-select size="small" v-model="ratio" placeholder="全部" clearable @change="getScenceList">
                                    <el-option v-for="item in ratio_list" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="search-box">
                                <el-input size="small" v-model="scene_name" @keyup.enter.native="getScenceList"
                                    placeholder="搜索形象关键字" suffix-icon="el-icon-search"></el-input>
                            </div>
                        </div>
                        <div class="model-list">
                            <div class="model-item" v-for="item in modelScene" :key="item.id">
                                <div class="model-item-c" :class="{ active: active == item.id }"
                                    @click="changeModelScene(item)">
                                    <img class="cover" :src="item.thumbnail" alt="">
                                </div>
                                <div class="model-item-text">
                                    {{ item.scene_name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="model-info-b">
                        <div class="model-nav">
                            <div class="model-tit" v-if="info.is_vip == 1" @click="type = 2">
                                <span>会员订阅</span>
                                <div class="line" v-if="type == 2"></div>
                            </div>
                            <div class="model-tit" :style="{ textAlign: info.is_vip == 1 ? 'center' : 'left' }"
                                @click="type = 1">
                                <span>套餐购买</span>
                                <el-tooltip placement="top">
                                    <div slot="content">
                                        <div class="tips-desc">· 有效期是指雇用模特的有效期限，到期后，模特将回收不能使用；</div>
                                        <div class="tips-desc" style="padding: 0;">· 有效期套餐内赠送您视频合成时长，若时长使用完，可进行充值；</div>
                                    </div>
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                                <div class="line" v-if="type == 1 && info.is_vip == 1"></div>
                            </div>
                        </div>
                        <div class="set-meal">
                            <div class="set-meal-item" v-if="type == 1">
                                <div class="set-meal-name">年度优惠套餐</div>
                                <div class="set-meal-time">使用期限：{{ info.expire_day }}</div>
                                <div class="set-meal-time">包含视频时长：{{ info.give_minute }}</div>
                            </div>
                            <div class="set-meal-vip" v-else>
                                <div class="vip-l">
                                    <div class="vip-desc">加入云川会员，可享受</div>
                                    <div class="vip-btn" @click="upgradeNowBtn">{{ userInfo.is_vip == 1 ? '续费会员' : '立即开通会员'
                                    }}</div>
                                </div>
                                <div class="vip-card">
                                    <div class="vip-card-item">
                                        <img src="@/assets/images/digital/vip_icon1.png" alt="">
                                        <span>VIP精品模特</span>
                                    </div>
                                    <div class="vip-card-item">
                                        <img src="@/assets/images/digital/vip_icon2.png" alt="">
                                        <span>时长包赠送</span>
                                    </div>
                                    <div class="vip-card-item">
                                        <img src="@/assets/images/digital/vip_icon3.png" alt="">
                                        <span>AI绘画专属</span>
                                    </div>
                                    <div class="vip-card-item">
                                        <img src="@/assets/images/digital/vip_icon4.png" alt="">
                                        <span>VIP精品音色</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="model-bottom" v-if="type == 1">
                <div class="tips-btn">购买须知
                    <el-tooltip placement="top">
                        <div slot="content">
                            <div class="tips">
                                <p class="dot"></p>
                                <span class="span">付费模式</span>
                            </div>
                            <div class="tips-desc">按天订阅套餐，有效期内使用</div>
                            <div class="tips">
                                <p class="dot"></p>
                                <span class="span">续费模式</span>
                            </div>
                            <div class="tips-desc">即将到期时，支持在我的数字人进行续费，不限套餐</div>
                            <div class="tips">
                                <p class="dot"></p>
                                <span class="span">使用范围</span>
                            </div>
                            <div class="tips-desc">限在云川平台使用数字人制作视频，数字名片等</div>
                            <div class="tips">
                                <p class="dot"></p>
                                <span class="span">退换说明</span>
                            </div>
                            <div class="tips-desc">商品为虚拟服务，购买后平台不支持退换，请谨慎选购</div>
                        </div>
                        <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                </div>
                <span>总计：</span>
                <img src="@/assets/images/person/accountManage/zuan.png" alt="">
                <span class="price">{{ info.price }}钻</span>
                <div class="confirm-btn btn_background_color_a" @click="getOrder">确认下单</div>
            </div>
        </div>
        <!-- 开通会员 -->
        <open-member ref='dialogMember'></open-member>
        <!-- 支付 -->
        <pay-diamond ref='dialogdiamond' @renovate='paySuccess'></pay-diamond>
    </div>
</template>

<script>
import { mapState } from "vuex"
import OpenMember from '@/components/invest_money/open_member.vue'//开通会员
import PayDiamond from '@/components/pay_page/pay_diamond.vue'  //支付

export default {
    components: { OpenMember, PayDiamond },
    data() {
        return {
            model_type_list: [],
            model_type: '',   //类型
            ratio_list: [
                {
                    value: 1,
                    label: '横版',
                },
                {
                    value: 2,
                    label: '竖版',
                }
            ],
            ratio: '',   //比例
            scene_name: '',    //输入框搜索
            id: '',
            loading: false,
            info: {             //模特详情
                labelList: [],
            },
            modelScene: [],   //模特分身列表
            active: '',
            modelSceneInfo: {},  //模特分身详情
            type: 1,  //套餐购买  2开通会员
        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.userInfo.user_info,
        }),
    },
    created() {
        if (this.$route.params.id) {
            var name = '模特-' + this.$route.query.name + '-详情'
            var key = this.$route.meta.key + '_' + this.$route.params.id
            this.id = this.$route.params.id
            this.$store.dispatch('tabsArr/getName', { name, key })
            this.getInfo()
            this.getParams()
        }
    },
    methods: {
        // 生成订单
        getOrder() {
            let obj = {
                id: this.id
            }
            this.$digitalApi.modelOrder(obj).then(res => {
                if (res.code == 1000) {
                    let parmas = {
                        diamond: this.userInfo.diamond,
                        video_price: this.info.price,
                        order_num: res.result.order_num,
                        order_id: res.result.order_id,
                        id: this.id,
                        name: this.info.name,
                        label: this.info.labelList,
                        expire_day: this.info.expire_day,
                        give_minute: this.info.give_minute,
                    }
                    this.$refs.dialogdiamond.openDialogBtn(parmas, 4)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 支付成功
        paySuccess() { },
        // 开通续费会员
        upgradeNowBtn() {
            this.$refs.dialogMember.openDialogBtn()
        },
        close() {
            this.active = '';
            this.modelSceneInfo = {};
        },
        // 获取模特详情
        getInfo() {
            this.loading = true
            this.$digitalApi.modelDetails(this.id).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.info = res.result
                    this.modelScene = res.result.modelScene
                    let arr = res.result.label.split(',')
                    this.info.labelList = arr
                    if (this.info.is_vip == 1) {
                        this.type = 2
                    } else {
                        this.type = 1
                    }
                }
            })
        },
        // 切换视频
        changeModelScene(val) {
            this.active = val.id;
            this.modelSceneInfo = val
        },
        // 获取分身
        getScenceList() {
            let obj = {
                page: 1,
                limit: 100,
                model_id: this.id,
                model_type: this.model_type,
                scene_name: this.scene_name,
                ratio: this.ratio,
            }
            this.$digitalApi.modelScenceList(obj).then(res => {
                if (res.code == 1000) {
                    this.modelScene = res.result.data
                }
            })
        },
        // 获取分身参数
        getParams() {
            this.$digitalApi.modelScenceParams().then(res => {
                if (res.code == 1000) {
                    this.model_type_list = res.result.model_type
                }
            })
        },
    },
}
</script>

<style scoped lang="scss">
.model-main {
    min-width: 1300px;
}

.model-info {
    display: flex;
    align-items: center;

    .model-info-l {
        width: 36%;
        margin-right: 20px;
        height: 767px;
        background: #ffffff;
        border-radius: 10px;
        padding: 20px 16px 20px 16px;

        .video-box {
            overflow: hidden;
            width: 100%;
            height: 443px;
            background: #f5f7fa;
            border-radius: 4px;
            position: relative;

            .video-t {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 80px;
                background: linear-gradient(180deg,
                        rgba(0, 0, 0, 0.68) 0%,
                        rgba(0, 0, 0, 0.05) 90%,
                        rgba(245, 247, 250, 0) 100%);
                border-radius: 2px;
                display: flex;
                flex-wrap: wrap;
                padding: 12px 0;
                z-index: 9;

                .video-t-l {
                    width: 30%;
                    font-size: 12px;
                    color: #ffffff;
                    line-height: 17px;
                    padding-bottom: 6px;
                    padding-left: 20px;

                    span {
                        margin-left: 6px;
                    }

                    .block {
                        background: rgba(255, 255, 255, 0.3);
                        border-radius: 13px;
                        padding: 2px 8px;
                        background: #d1d8ff;
                        color: #2e4bf2;
                    }
                }

                .close {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    z-index: 10;
                    width: 32px;
                    height: 32px;
                    background: rgba(80, 81, 83, 0.5);
                    border-radius: 50%;
                    line-height: 32px;
                    text-align: center;
                    cursor: pointer;

                    i {
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }

            .video-c {
                height: 443px;
                display: flex;
                justify-content: center;

                .cover {
                    height: 100%;
                    width: auto;
                    // background: #cecfdb;
                    margin: 0 auto;
                }

                .video_cover {
                    height: 100%;
                    width: auto;
                    margin: 0 auto;
                }
            }
        }

        .video-info {
            width: 100%;

            .video-info-t1 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 16px;

                .name {
                    font-size: 24px;
                    color: #333333;
                    line-height: 33px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-left: 10px;
                    }
                }

                .hot {
                    font-size: 14px;
                    color: #d81e06;
                    line-height: 20px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
            }

            .video-info-t2 {
                display: flex;
                align-items: center;
                width: 100%;
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                padding-top: 15px;

                span {
                    margin-right: 10px;
                }

                .label {
                    font-size: 12px;
                    color: #2e4bf2;
                    background: rgba(46, 75, 242, 0.1);
                    border-radius: 13px;
                    padding: 4px 13px;
                    line-height: 16px;
                }
            }

            .banner {
                width: 100%;
                height: 100px;
                background: url(~@/assets/images/digital/share_bg.png) no-repeat 100% / cover;
                border-radius: 10px;
                margin-top: 20px;
                display: flex;
                align-items: center;
                padding: 20px;
                position: relative;

                .head {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background: #ffffff;
                }

                .text {
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                    line-height: 22px;
                    padding-left: 16px;
                }

                .copy {
                    position: absolute;
                    width: 96px;
                    height: 36px;
                    background: #ffffff;
                    border-radius: 18px;
                    top: 35px;
                    right: 30px;
                    font-size: 14px;
                    color: #7735ff;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }

    .model-info-r {
        width: 64%;
        height: 767px;

        .model-info-t {
            width: 100%;
            height: 465px;
            border-radius: 10px;
            background: #ffffff;
            padding: 20px;

            .model-info-header {
                display: flex;
                align-items: center;
                position: relative;

                .tit {
                    font-size: 12px;
                    color: #333333;
                    line-height: 17px;
                    padding-right: 10px;
                }

                .select-box {
                    width: 102px;
                    margin-right: 20px;
                }

                .search-box {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 178px;
                }
            }

            .model-list {
                width: 100%;
                height: 373px;
                background: #f8f8f8;
                border-radius: 10px;
                margin-top: 20px;
                padding: 20px;
                display: flex;
                flex-wrap: wrap;
                overflow-y: auto;

                .model-item {
                    width: 324px;
                    margin-right: 20px;
                    margin-bottom: 10px;

                    .model-item-c {
                        width: 324px;
                        height: 182px;
                        background: #e7f1ff;
                        border: 2px solid #f8f8f8;
                        cursor: pointer;

                        .cover {
                            height: 100%;
                            width: auto;
                            margin: 0 auto;
                        }
                    }

                    .model-item-c.active {
                        border: 2px solid #2e4bf2;
                    }

                    .model-item-text {
                        text-align: center;
                        font-size: 12px;
                        color: #333333;
                        padding-top: 8px;
                    }
                }
            }

            /* 自定义滚动条 */
            .model-list::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 4px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 8px;
            }

            .model-list::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
                background: rgba(0, 0, 0, 0.2);
            }

            .model-list::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                -webkit-box-shadow: inset 0 0 5px #f8f8f8;
                border-radius: 5px;
                background: #f8f8f8;
            }
        }

        .model-info-b {
            width: 100%;
            height: 282px;
            border-radius: 10px;
            background: #ffffff;
            padding: 0 20px 20px;
            margin-top: 20px;

            .model-nav {
                display: flex;
                height: 72px;
                border-bottom: 1px solid #ededed;

                .model-tit {
                    width: 50%;
                    text-align: center;
                    padding-top: 24px;
                    cursor: pointer;

                    .line {
                        width: 28px;
                        height: 3px;
                        background: #2e4bf2;
                        border-radius: 2px;
                        margin: 8px auto 0;
                    }
                }
            }

            .set-meal {
                display: flex;
                align-items: center;
                margin-top: 20px;

                .set-meal-item {
                    width: 400px;
                    height: 165px;
                    background: linear-gradient(135deg,
                            #969afe 0%,
                            #676cff 100%);
                    background: url(~@/assets/images/digital/card_bg.png) no-repeat 100% / 100%;
                    border-radius: 10px;
                    padding: 24px 30px;

                    .set-meal-name {
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 25px;
                        padding: 12px 0;
                    }

                    .set-meal-time {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                        padding-bottom: 8px;
                    }
                }

                .set-meal-vip {
                    width: 100%;
                    height: 165px;
                    background: url(~@/assets/images/digital/vip_bg.png) no-repeat 100% / cover;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 100px;

                    .vip-l {
                        .vip-desc {
                            font-size: 20px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 28px;
                        }

                        .vip-btn {
                            width: 130px;
                            height: 38px;
                            background: linear-gradient(90deg,
                                    #4875ff 0%,
                                    #2b4afe 100%);
                            border-radius: 4px;
                            line-height: 38px;
                            text-align: center;
                            color: #ffffff;
                            font-size: 14px;
                            margin-top: 20px;
                            cursor: pointer;
                        }
                    }

                    .vip-card {
                        width: 480px;
                        height: 125px;
                        background: #dce5ff;
                        border-radius: 6px;
                        border: 1px solid #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;

                        .vip-card-item {
                            display: flex;
                            align-items: center;
                            width: 50%;
                            padding-left: 60px;

                            img {
                                width: 40px;
                                height: 40px;
                                margin-right: 10px;
                            }

                            span {
                                font-size: 14px;
                                color: #333333;
                            }
                        }
                    }
                }
            }
        }
    }
}

.model-bottom {
    width: 100%;
    height: 80px;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;

    .tips-btn {
        width: 86px;
        height: 26px;
        border-radius: 4px;
        line-height: 26px;
        text-align: center;
        color: #ff7432;
        background: rgba(255, 116, 50, 0.12);
        margin-right: 20px;
    }

    span {
        color: $bColor3;
        line-height: 20px;
    }

    .price {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 20px;
        margin-left: 4px;
    }

    img {
        width: 18px;
        height: 18px;
    }

    .confirm-btn {
        width: 76px;
        height: 30px;
        border-radius: 2px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
    }
}

.tips {
    display: flex;
    align-items: center;
    padding-bottom: 5px;

    .dot {
        width: 5px;
        height: 5px;
        background: $blueColor1;
    }

    .span {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 16px;
        padding-left: 8px;
    }
}

.tips-desc {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 17px;
    padding-bottom: 10px;
}

.model-tit {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;

    i {
        padding-left: 4px;
        color: #b0b0b0;
        font-size: 16px;
    }
}</style>
